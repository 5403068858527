import React from 'react';
import { Routes, Route } from 'react-router-dom';
import MetricsServiceDetails from './details_v2/metrics_service_details';
import BusinessMetricProvider from './business_metric_provider';
import Root from './root';

const MetricsServiceValuesRouterV2 = (): JSX.Element | null => {
  return (
    <Routes>
      <Route path="/businesses/:businessId/metrics_service">
        <Route element={ <BusinessMetricProvider /> } path=":businessMetricId">
          <Route element={ <MetricsServiceDetails /> } path="values" />
        </Route>
        <Route index element={ <Root /> } />
      </Route>
    </Routes>
  );
};

export default React.memo(MetricsServiceValuesRouterV2);