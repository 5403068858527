import React from 'react';

import DetailsRegion from '@src/components/ui_v2/layout/details_region';

import Header from './header';
import useMetricValuesCollection from './hooks';
import List from './list_item/metric_values';
import { useBusinessMetricContext } from '../business_metric_context';

import styles from './styles.module.scss';

const MetricsServiceDetails = (): JSX.Element => {
  const metric = useBusinessMetricContext();

  const collection = useMetricValuesCollection({
    businessMetricId: metric.id!,
  });

  if (!metric) {
    return (
      <div className={ styles.centeredContainer }>
        Error loading metric detail. Please try again later.
      </div>
    );
  }

  return (
    <div className={ styles.centeredContainer }>
      <DetailsRegion>
        <DetailsRegion.Header title={ metric.name }>
          <Header collection={ collection } metric={ metric } />
        </DetailsRegion.Header>
        <List collection={ collection } metric={ metric } />
      </DetailsRegion>
    </div>
  );
};

export default React.memo(MetricsServiceDetails);
