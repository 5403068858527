import React from 'react';

import compact from 'lodash/compact';
import pluralize from 'pluralize';

import { Button } from '@src/components/ui_v2/buttons';

import { IAddAdjustmentValues } from '../schema';

import styles from './styles.module.scss';

interface ICategorySplitsValueProps {
  value: IAddAdjustmentValues['categorySplits'],
  onEdit: () => void,
  summaryTitle?: string,
}

const CategorySplitsValue = ({
  value,
  onEdit,
  summaryTitle,
}: ICategorySplitsValueProps): JSX.Element => {
  const isInvoice = summaryTitle === 'Invoice';

  if (!value || value.length === 0 || value.every((item) => !item.chartOfAccountId)) {
    return <Button className={ isInvoice ? styles['invoice-select-button'] : undefined } variant="link" onClick={ onEdit }>Select Category</Button>;
  }

  if (value.length === 1) {
    const categoryName = compact([
      value[0].accountingClassName,
      value[0].chartOfAccountName,
    ]).join(' | ');

    return (
      <span className={ isInvoice ? styles['invoice-category-name'] : undefined }>
        { categoryName }
        <br />
        <Button className={ isInvoice ? styles['invoice-edit-button'] : undefined } variant="link" onClick={ onEdit }>Edit</Button>
      </span>
    );
  }

  return (
    <Button className={ isInvoice ? styles['invoice-splits-button'] : undefined } variant="link" onClick={ onEdit }>
      { `${value.length} ${pluralize('Split', value.length)}` }
    </Button>
  );
};

export default React.memo(CategorySplitsValue);
