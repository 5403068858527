import { useMemo } from 'react';

import { flatten, orderBy } from 'lodash';
import moment from 'moment';

import { Section } from '@src/constants/sections';
import { useGetReportRefreshLogs } from '@src/hooks/queries/report_service/report_refresh_logs';
import { useSorting } from '@src/hooks/url_params';
import { TMongoID, TSection } from '@src/types/common';
import { IReportRefreshLogData, TReportRefreshLogSortColumns } from '@src/types/report_service/report_refresh_logs';
import { ISorting, ISortingParams } from '@src/types/sorting';
import { endOfMonthApiDate, parseApiDate, startOfMonthApiDate } from '@src/utils/date_helpers';

import { useFilterData } from '@src/components/ui_v2/filter';

interface IReportRefreshLogsCollection {
  records: IReportRefreshLogData[],
  section: TSection,
  sorting: ISorting<TReportRefreshLogSortColumns>,
}

const defaultSorting: ISortingParams<TReportRefreshLogSortColumns> = {
  orderColumn:    'jobStartTime',
  orderDirection: 'asc',
};

const getJobTypeTitle = (item: IReportRefreshLogData) => {
  switch (item.jobType) {
    case 'mid_night':
      return 'auto update scheduled refresh';
    case 'webhook':
      return 'auto update';
    case 'manual':
      return `manual update`;
    case 'force_refresh':
      return `force refresh`;
    default:
      return '';
  }
};

const updateDataValuesForJobType = (data: IReportRefreshLogData[]) => {
  return data.map((item) => (
    {
      ...item,
      jobTypeTitle: getJobTypeTitle(item),
    }
  ));
};

const getFilteredData = (
  data: IReportRefreshLogData[],
  filteredData: any,
) => {
  let tempData = [...data];
  if (filteredData.status) {
    tempData = tempData.filter((item) => item.status === filteredData.status);
  }
  if (filteredData.type) {
    tempData = tempData.filter((item) => item.jobType === filteredData.type);
  }
  if (filteredData.dateRange) {
    const paramStartDate = startOfMonthApiDate(parseApiDate(filteredData?.dateRange?.gte));
    const paramEndDate = endOfMonthApiDate(parseApiDate(filteredData?.dateRange?.lte));
    tempData = tempData.filter((item) => {
      const startDate = moment(item.jobStartTime).format('YYYY-MM-DD');
      const endDate = moment(item.jobEndTime).format('YYYY-MM-DD');
      const date1 = moment(paramStartDate).isSameOrBefore(startDate);
      const date2 = moment(paramEndDate).isSameOrAfter(endDate);
      return date1 && date2;
    });
  }
  return tempData;
};

const getSortedData = (
  data: IReportRefreshLogData[],
  orderColumn?: TReportRefreshLogSortColumns,
  orderDirection?: 'asc' | 'desc',
) => {
  if (orderColumn) {
    return orderBy(data, [orderColumn], orderDirection);
  }
  return data;
};

const useRefreshLogsCollection =
  (reportId: TMongoID): IReportRefreshLogsCollection => {
    const section = useMemo(() => {
      return {
        section: Section.ReportRefreshLogs,
      };
    }, []);

    const filterData = useFilterData(section);

    const sorting = useSorting<TReportRefreshLogSortColumns>({
      section: section.section,
      defaultSorting,
    });

    const query = useGetReportRefreshLogs({ reportId });

    const records = useMemo(() => {
      const { orderColumn, orderDirection } = sorting.data;
      const pages = query?.data?.pages || [];
      const collection = flatten(pages.map((p) => p.collection));
      const updatedValues = updateDataValuesForJobType(collection);
      const hasFilter = filterData && (Object.keys(filterData).length > 0);
      const filteredData = hasFilter ? getFilteredData(updatedValues, filterData) : updatedValues;
      const sortedData = getSortedData(filteredData, orderColumn, orderDirection);
      return sortedData;
    }, [query?.data?.pages, sorting, filterData]);

    return {
      records,
      section,
      sorting,
    };
  };

export {
  IReportRefreshLogsCollection,
  useRefreshLogsCollection,
};
