import React, { useCallback, useEffect, useState } from 'react';

import { useMetricsServiceContext } from '@src/hooks/contexts/metrics_service_context';
import { MetricReportStatusEnum } from '@src/types/metrics/metrics_reports';

import Alert from '@src/components/ui_v2/alerts';
import DetailsRegion from '@src/components/ui_v2/layout/details_region';
import TableSection from '@src/components/ui_v2/table_section/table_section';
import { InfoFilledIcon } from '@src/components/utils/icomoon';
import Section from '@src/components/utils_v2/section';

import { useMetricsReportCollection } from './hooks';
import Table from './table';

const statusLabelVariant = (status: MetricReportStatusEnum) => {
  switch (status) {
    case MetricReportStatusEnum.SUCCESS:
    case MetricReportStatusEnum.DATE_ASSIGNED:
      return 'highlight';
    case MetricReportStatusEnum.FAILED:
    case MetricReportStatusEnum.DATE_UNASSIGNED:
      return 'error';
    case MetricReportStatusEnum.PENDING:
    case MetricReportStatusEnum.PROCESSING:
    case MetricReportStatusEnum.QUEUED:
      return 'info';
    default:
      return 'warning';
  }
};

const statusText = (status: MetricReportStatusEnum, error: string | null) => {
  switch (status) {
    case MetricReportStatusEnum.SUCCESS:
    case MetricReportStatusEnum.DATE_ASSIGNED:
      return (
        <p>
          <b>Success</b>
          {' '}
          : File has been successfully parsed.
        </p>
      );
    case MetricReportStatusEnum.DATE_UNASSIGNED:
      return (
        <p>
          <b>Error</b>
          {' '}
          : Date unassigned.
        </p>
      );
    case MetricReportStatusEnum.FAILED:
      return (
        <p>
          <b>Error</b>
          {' '}
          :
          {error}
        </p>
      );
    case MetricReportStatusEnum.PENDING:
    case MetricReportStatusEnum.PROCESSING:
    case MetricReportStatusEnum.QUEUED:
      return (
        <p>
          <b>Processing</b>
          {' '}
          : File has processing.
        </p>
      );
    default:
      return '';
  }
};

const ForReviewMetrics = (): JSX.Element => {
  const metricService = useMetricsServiceContext();
  const [assignDateStatus, setAssignDateStatus] = useState<MetricReportStatusEnum | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [showAlert, setShowAlert] = useState(false);

  const handleMetricReportUpdated = useCallback((status: MetricReportStatusEnum, error: string) => {
    setAssignDateStatus(status);
    setShowAlert(true);
    setError(error);
  }, [setAssignDateStatus, setShowAlert, setError]);

  const collection = useMetricsReportCollection(
    { businessId: metricService.businessId, onMetricReportUpdated: handleMetricReportUpdated },
  );

  const handleCloseAlert = useCallback(() => {
    setShowAlert(false);
  }, []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => {
        handleCloseAlert();
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [showAlert, handleCloseAlert]);

  return (
    <DetailsRegion>
      {showAlert && assignDateStatus && (
        <Alert
          icon={ <InfoFilledIcon /> }
          variant={ statusLabelVariant(assignDateStatus) }
          onClose={ handleCloseAlert }
        >
          {statusText(assignDateStatus, error)}
        </Alert>
      )}
      <DetailsRegion.Header
        title="For Review"
      />
      <Section.Provider section={ collection.section }>
        <TableSection>
          <Table
            collection={ collection }
          />
        </TableSection>
      </Section.Provider>
    </DetailsRegion>
  );
};

export default ForReviewMetrics;
