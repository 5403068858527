import React from 'react';

import waitingImg from '@assets/images/icons/report-refresh-waiting-icon.svg';

import ImgIcon, { IImgIconProps } from './img_icon';

const ReportRefreshWaitingIcon = (props: IImgIconProps): JSX.Element => {
  return (
    <ImgIcon
      alt="Report Refresh waiting icon"
      src={ waitingImg }
      { ...props }
    />
  );
};

export default ReportRefreshWaitingIcon;
