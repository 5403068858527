import React from 'react';

import noChangeImg from '@assets/images/icons/report-refresh-no-change.svg';

import ImgIcon, { IImgIconProps } from './img_icon';

const ReportRefreshNoChangeIcon = (props: IImgIconProps): JSX.Element => {
  return (
    <ImgIcon
      alt="No change icon"
      src={ noChangeImg }
      { ...props }
    />
  );
};

export default ReportRefreshNoChangeIcon;
