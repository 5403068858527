import React, { useEffect } from 'react';

import { isArray } from 'lodash';

import { useGetReportById } from '@src/hooks/queries/report_service/custom_reports';
import { TMongoID } from '@src/types/common';
import { IReportRefreshLogData } from '@src/types/report_service/report_refresh_logs';
import { formatDate } from '@src/utils/date_helpers';

import { UserAvatar } from '@src/components/ui/avatars';
import Filter from '@src/components/ui_v2/filter';
import { CrossIcon } from '@src/components/utils/icomoon';
import { FailedIcon, GreenTickIcon, InProgressIcon, ReportRefreshNoChangeIcon, ReportRefreshWaitingIcon } from '@src/components/utils/img_icons';
import Section from '@src/components/utils_v2/section';

import { useRefreshLogsCollection } from './hooks';

interface IReportRefreshLogsProps {
  reportId: TMongoID,
}

const ReportRefreshLogs = ({ reportId }: IReportRefreshLogsProps) => {
  const { data, refetch: refetchReportData, isLoading } = useGetReportById(reportId);

  useEffect(() => {
    if (!data) {
      refetchReportData();
    }
  }, [data, refetchReportData]);

  const collection = useRefreshLogsCollection(reportId);

  const formateDateValue = (dateTime: string | null) => {
    if (!dateTime) return '-';
    return formatDate(dateTime, 'ddd, MMM D, YYYY | hh:mm A');
  };

  const renderPeriod = (range?: string[]) => {
    if (isArray(range) && range.length > 0) {
      const d1 = range[0];
      const d2 = range[range.length - 1];
      const startDate = formatDate(d1, 'MMM YYYY');
      const endDate = formatDate(d2, 'MMM YYYY');
      return (
        <span>
          for period -&nbsp;
          <span className="font-bold">
            {`${startDate} to ${endDate}`}
          </span>
          ,&nbsp;
        </span>
      );
    }
    return <span />;
  };

  const renderStatusIcon = (status: string) => {
    switch (status) {
      case 'completed':
        return <GreenTickIcon />;
      case 'failed':
        return <FailedIcon />;
      case 'waiting':
        return <ReportRefreshWaitingIcon />;
      case 'running':
        return <InProgressIcon />;
      case 'nochange':
        return <ReportRefreshNoChangeIcon />;
      default:
        return <span />;
    }
  };

  const renderStatusText = (status: string) => {
    switch (status) {
      case 'completed':
        return <span className="font-bold completed-text text-uppercase">Success</span>;
      case 'waiting':
        return <span className="font-bold waiting-text text-uppercase">Waiting In Queue</span>;
      case 'failed':
        return <span className="font-bold failed-text text-uppercase">Failed</span>;
      case 'running':
        return <span className="font-bold in-progress-text text-uppercase">In Progress</span>;
      case 'nochange':
        return <span className="font-bold no-change-text text-uppercase">No Change</span>;
      default:
        return <span />;
    }
  };

  const renderInitiatedBy = (log: IReportRefreshLogData) => {
    if (log.jobType === 'webhook') {
      return <span>was triggered by change detected in GL</span>;
    } 
    return (
      <>
        requested by&nbsp;
        <UserAvatar avatar={ { "avatarUrl": log?.avatar } } className="user-profile" mr={ 5 } size={ 24 } />
        <span className="text-capital font-bold">{log.userName}</span>
      </>
    );
  };

  if (isLoading) {
    return (
      <div className="spinner-open">
        <div className="spinner-overlay">
          <i className="fa fa-spinner fa-pulse" />
        </div>
      </div>
    );
  }

  return (
    <div className="report-refresh-logs">
      <div className="header">
        <div className="empty-box" />
        <h2 className="header-title">{`${data?.name || ''} Refresh Activity Log`}</h2>
        <div
          aria-label="Close Page"
          role="button"
          tabIndex={ 0 }
          onClick={ () => Backbone.history.history.back() }
        >
          <CrossIcon fontSize={ 24 } />
        </div>
      </div>
      <Section.Provider section={ collection.section }>
        <div className="logs-container">
          <div className="date-filter">
            <Filter.TableContainer>
              <Filter.DateField
                name="dateRange"
                periodType="monthly"
              />
            </Filter.TableContainer>
          </div>

          {collection.records.length === 0 && (
            <div className="no-records">
              No records found
            </div>
          )}

          {collection.records.map((log) => {
            return (
              <div key={ log.jobId } className="log-row">
                <div className="status-icon">{renderStatusIcon(log.status)}</div>
                <span className="main-text">
                  {renderStatusText(log.status)}
                  &nbsp;
                  :
                  &nbsp;
                  <span className="font-13">{formateDateValue(log.jobStartTime)}</span>
                  &nbsp;
                  {data?.name}
                  &nbsp;
                  <span className="font-bold">{log.jobTypeTitle}</span>
                  &nbsp;
                  {log.monthsInRange && renderPeriod(log.monthsInRange)}
                  {log.status === 'completed' && (
                    <>
                      <span>Completed at</span>
                      &nbsp;
                      <span className="font-bold">{formateDateValue(log.jobEndTime)}</span>
                      &nbsp;
                    </>
                  )}
                  {renderInitiatedBy(log)}
                </span>
              </div>
            );
          })}
        </div>
      </Section.Provider>
    </div>
  );
};

export default ReportRefreshLogs;
