import React from 'react';

import greenTickImg from '@assets/images/icons/green-tick.svg';

import ImgIcon, { IImgIconProps } from './img_icon';

const GreenTickIcon = (props: IImgIconProps): JSX.Element => {
  return (
    <ImgIcon
      alt="Green Tick icon"
      src={ greenTickImg }
      { ...props }
    />
  );
};

export default GreenTickIcon;
