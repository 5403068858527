import React from 'react';

import { IBusinessMetricValue, TBusinessMetricValueSortColumn } from '@src/types/business_metric_value';
import { IMetric } from '@src/types/metrics';

import Spinner from '@src/components/ui/spinner';
import CollectionTable from '@src/components/ui_v2/collection_table';
import Section from '@src/components/utils_v2/section';

import { IMetricValuesCollection } from '../hooks';

interface IMetricDetailsListParams {
  metric: IMetric,
  collection: IMetricValuesCollection
}

const MetricDetailsList = ({ metric, collection }: IMetricDetailsListParams): JSX.Element => {
  return (
    <div>
      {collection.query.isLoading ? <Spinner /> : null}
      {collection.query.isError ? <div>Error loading metrics. Please try again later.</div> : null}
      <Section.Provider section={ collection.section }>
        <CollectionTable<
          IBusinessMetricValue,
          TBusinessMetricValueSortColumn
        >
          noDataMessage={ (
            <>
              No data available for
              {' '}
              { metric.name }
            </>
          ) }
          records={ collection.records }
          sorting={ collection.sorting }
        >
          <CollectionTable.DateColumn<
            IBusinessMetricValue,
            TBusinessMetricValueSortColumn
          >
            name="date"
            sortColumn="date"
            title="Date"
            value={ (metricValue) => metricValue.date }
            width="25%"
          />
          <CollectionTable.TextColumn<
            IBusinessMetricValue,
            TBusinessMetricValueSortColumn
          >
            name="value"
            sortColumn="value"
            title={ metric.name }
            value={ (metricValue) => metricValue.value?.toString() || '' }
            width="25%"
          />
        </CollectionTable>
      </Section.Provider>
    </div>
  );
};

export default MetricDetailsList;
