import React from 'react';

import classNames from 'classnames';

import { IMetric } from '@src/types/metrics';
import { formatDate } from '@src/utils/date_helpers';

import { useChangeStartDateModal } from '@src/components/metrics_service/details/list_item/change_start_date';
import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import Filter from '@src/components/ui_v2/filter';
import { MetircsChangeStartDateIcon } from '@src/components/utils/icomoon';
import Section from '@src/components/utils_v2/section';

import { IMetricValuesCollection } from '../hooks';

import styles from '../styles.module.scss';

interface MetricsServiceHeaderProps {
  metric: IMetric,
  collection: IMetricValuesCollection
}

const MetricsServiceHeader = ({ metric, collection }: MetricsServiceHeaderProps): JSX.Element => {
  const changeStartDate = useChangeStartDateModal();
  return (
    <>
      <div className={ styles['item-container'] }>
        <div className={ styles['item-description'] }>
          <span>
            Last Updated
          </span>
          <span>
            <strong>
              { formatDate(metric.lastUpdatedAt) }
            </strong>
          </span>
        </div>
      </div>
      <div className={ classNames(styles['item-container'], styles['divider-vertical']) }>
        <div className={ styles['item-description'] }>
          <span>
            Start Date
          </span>
          <span>
            <strong>
              { formatDate(metric.startDate) }
            </strong>
          </span>
        </div>
      </div>
      <div className={ classNames(styles['item-container'], styles['divider-vertical']) }>
        <div className={ styles['item-description'] }>
          <span>
            Frequency
          </span>
          <span>
            <strong>
              { metric.frequency }
            </strong>
          </span>
        </div>
      </div>

      <div className={ styles['item-container'] }>
        <Section.Provider section={ collection.section }>
          <Filter.TableContainer>
            <Filter.MonthField name="month" />
          </Filter.TableContainer>
        </Section.Provider>
      </div>

      <changeStartDate.Component { ...changeStartDate.props } metric={ metric } />
      <ActionsDropdown className={ styles['ml-auto'] }>
        <ActionsDropdown.Action
          icon={ <MetircsChangeStartDateIcon fontSize={ 20 } /> }
          title="Change Start Date"
          onClick={ changeStartDate.open }
        />
      </ActionsDropdown>
    </>
  );
};

export default React.memo(MetricsServiceHeader);
