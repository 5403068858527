import { useCallback } from 'react';

import { useMutation, useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import { useMetricsReportFayeChannel } from '@src/hooks/faye/metrics_report';
import { createUseGetInfiniteCollection } from '@src/hooks/queries/infinite_collection_queries';
import { IAssignMetricsReportDateParams,
  IDeleteMetricsReportParams,
  IGetMetricsReportsParams,
  IGetMetricsReportsResponse,
  deleteMetricsReport,
  getMetricsReports,
  assignMetricReportDate,
  IAssignMetricsReportDateResponse } from '@src/requests/metrics/metrics_reports';
import { IMetricsReport, MetricReportStatusEnum } from '@src/types/metrics/metrics_reports';

import { IBulkMutationParams, IBulkMutationReturn, makeBulkRequest } from '../bulk_mutations';

const useGetInfiniteMetricsReports = createUseGetInfiniteCollection<
IMetricsReport,
IGetMetricsReportsParams,
IGetMetricsReportsResponse
>({
  queryKey: QueryKey.metricsReports,
  request:  getMetricsReports,
});

const useGetMetricsReports = (
  params: IGetMetricsReportsParams,
  onMetricReportUpdated: (status: MetricReportStatusEnum, error: string) => void,
) => {
  const query = useGetInfiniteMetricsReports(params);
  const { refetch } = query;

  const handleEventReceived = useCallback((event) => {
    if (event.eventName !== 'metrics_report_updated') return;

    refetch();
    onMetricReportUpdated(event.event.status, event.event.error);
  }, [refetch, onMetricReportUpdated]);

  useMetricsReportFayeChannel(params.businessId, handleEventReceived);

  return query;
};

const useAssignMetricsReportDate = () => {
  const queryClient = useQueryClient();

  return useMutation<
    IAssignMetricsReportDateResponse,
    Error,
    IAssignMetricsReportDateParams
  >(
    assignMetricReportDate,
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(QueryKey.metricsReports);
      },
    },
  );
};

const useBulkDeleteMetricsReport = () => {
  const queryClient = useQueryClient();

  return useMutation<
    IBulkMutationReturn,
    Error,
    IBulkMutationParams<IDeleteMetricsReportParams>
  >(
    makeBulkRequest(deleteMetricsReport),
    {
      onSettled: () => {
        queryClient.invalidateQueries(QueryKey.metricsReports);
      },
    },
  );
};

export {
  useAssignMetricsReportDate,
  useGetInfiniteMetricsReports,
  useGetMetricsReports,
  useBulkDeleteMetricsReport,
};
