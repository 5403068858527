import { useMemo } from 'react';

import { useGetBusinessMetricsValue } from '@src/hooks/queries/business_metric_value';
import { useSorting } from '@src/hooks/url_params';
import { IBusinessMetricValue, TBusinessMetricValueSortColumn } from '@src/types/business_metric_value';
import { ISortingParams, ISorting } from '@src/types/sorting';
import { apiMonthToDate, endOfMonthApiDate, getUTCTimezone, formatApiMonth, parseApiMonth } from '@src/utils/date_helpers';

import { useFilterData } from '@src/components/ui_v2/filter';

interface IUseMetricValuesCollectionParams {
  businessMetricId: string;
}

export interface IMetricValuesCollection {
  query: ReturnType<typeof useGetBusinessMetricsValue>;
  records: IBusinessMetricValue[];
  sorting: ISorting<TBusinessMetricValueSortColumn>;
  section: { businessMetricId: string; section: string };
}

const defaultSorting: ISortingParams<TBusinessMetricValueSortColumn> = {
  orderColumn:    'date',
  orderDirection: 'asc',
};
const defaultFilter = {
  month: formatApiMonth(new Date()),
};

const useMetricValuesCollection = ({
  businessMetricId,
}: IUseMetricValuesCollectionParams): IMetricValuesCollection => {
  const section = useMemo(() => ({
    businessMetricId,
    section: window.Docyt.Common.Constants.METRIC_TYPES.BUSINESS_METRIC_VALUE,
  }), [businessMetricId]);

  const sorting = useSorting<TBusinessMetricValueSortColumn>({
    section: section.section,
    defaultSorting,
  });

  const filterData = useFilterData(section, defaultFilter);
  const date = filterData?.month ? formatApiMonth(parseApiMonth(filterData?.month))
    : formatApiMonth(new Date());
  const query = useGetBusinessMetricsValue({
    businessMetricId,
    from:    apiMonthToDate(date),
    to:      endOfMonthApiDate(getUTCTimezone(new Date(date))),
    filters: undefined,
  });

  const sortedRecords = useMemo(() => {
    if (!query.data) return [];

    const data = query.data.pages.flatMap((page) => page.collection) || [];
    const { orderColumn, orderDirection } = sorting.data;
    const orderMultiplier = orderDirection === 'asc' ? 1 : -1;

    if (!orderColumn) {
      return data;
    }

    return data.sort((a, b) => {
      const aValue = a[orderColumn as keyof IBusinessMetricValue] ?? 0;
      const bValue = b[orderColumn as keyof IBusinessMetricValue] ?? 0;

      if (aValue < bValue) {
        return -1 * orderMultiplier;
      } if (aValue > bValue) {
        return 1 * orderMultiplier;
      }
      return 0;
    });
  }, [query.data, sorting]);

  return {
    query,
    records: sortedRecords,
    section,
    sorting,
  };
};

export default useMetricValuesCollection;
