import React from 'react';

import failedImg from '@assets/images/icons/failed.svg';

import ImgIcon, { IImgIconProps } from './img_icon';

const FailedIcon = (props: IImgIconProps): JSX.Element => {
  return (
    <ImgIcon
      alt="Failed icon"
      src={ failedImg }
      { ...props }
    />
  );
};

export default FailedIcon;
