import React from 'react';

import inProgressImg from '@assets/images/icons/in-progress.svg';

import ImgIcon, { IImgIconProps } from './img_icon';

const InProgressIcon = (props: IImgIconProps): JSX.Element => {
  return (
    <ImgIcon
      alt="In Progress icon"
      src={ inProgressImg }
      { ...props }
    />
  );
};

export default InProgressIcon;
